import React from "react"
import { Typography } from "antd"
import PropTypes from "prop-types"
import style from "./SeoHeader.module.scss"

class SeoHeader extends React.Component {
  static propTypes = {
    seo: PropTypes.shape({
      level: PropTypes.number,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    }),
    human: PropTypes.shape({
      level: PropTypes.number,
      content: PropTypes.string.isRequired,
    }),
    marginBottom: PropTypes.number,
    marginTop: PropTypes.number,
  }

  static defaultProps = {
    seo: {
      level: 1,
      content: null,
    },
    human: {
      level: 2,
      content: null,
    },
    marginBottom: 12,
    marginTop: 24,
  }

  render() {
    const { human, seo } = this.props

    const seoProps = {
      className: style.seo,
      level: seo.level,
    }

    const humanProps = {
      className: style.human,
      level: human.level,
    }

    const wrapperProps = {
      style: {
        marginBottom: this.props.marginBottom,
        marginTop: this.props.marginTop,
      },
    }

    return (
      <div {...wrapperProps}>
        <Typography.Title {...seoProps}>{seo.content}</Typography.Title>
        <Typography.Title {...humanProps}>{human.content}</Typography.Title>
      </div>
    )
  }
}

export default SeoHeader
