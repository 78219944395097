import React from "react"
import { Button, Col, Divider, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import RestaurantForm from "../components/Forms/RestaurantForm"
import style from "../styles/pages/dining.module.scss"

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const colProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  xxl: 14,
  style: {
    marginBottom: 24,
    marginTop: 24,
    textAlign: `center`,
  },
}

const contactRowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

export default ({ data, location }) => {
  const { hero, beerImg, diningRoomImg, restaurant, cocktailImg } = data
  const { logo } = restaurant

  // Reference: https://schema.org/Restaurant
  const jsonld = {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    acceptsReservations: "Yes",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Asheville",
      addressRegion: "NC",
      postalCode: "28803",
      streetAddress: "5 Caledonia Rd",
    },
    currenciesAccepted: "USD",
    email: "info@azaleakitchen.com",
    image: hero.fluid.src,
    logo: logo.file.url,
    name: "Azalea Bar & Kitchen",
    openingHours: ["Su-Th 07:00-22:00", "Fr-Sa 07:00-24:00"],
    paymentAccepted: "Cash, Credit Card",
    priceRange: "$$",
    servesCuisine: ["American", "Fusion"],
    telephone: "(828) 774-5969",
    url: "https://www.residencesatbiltmore.com/azalea-bar-kitchen/",
  }

  const heroProps = {
    contentfulAsset: hero,
    height: `55vh`,
    gatsbyImageProps: {
      objectPosition: `50% 100%`,
    },
  }

  // const diningRoomImgProps = {
  //   alt: diningRoomImg.description,
  //   fluid: diningRoomImg.fluid,
  //   style: {
  //     borderRadius: 4,
  //     boxShadow: `0 5px 10px rgba(0,0,0,0.15)`,
  //     margin: `auto`,
  //     width: `100%`,
  //   },
  // }

  const menuHeaderColProps = {
    xs: 22,
    md: 20,
    lg: 18,
    xl: 16,
  }

  const contactColProps = {
    xs: 22,
    md: 10,
    lg: 9,
    xl: 8,
    style: { textAlign: `center` },
  }

  const formColProps = {
    xs: 22,
    md: 10,
    lg: 9,
    xl: 8,
  }

  // first sentence of description
  const metaDescription =
    restaurant.description.description.split(`. `)[0] + `.`

  return (
    <Shell>
      <SEO
        meta={{
          description: metaDescription,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        overrideDefaultSchema
        schema={JSON.stringify(jsonld)}
        title="Azalea Bar & Kitchen - The Residences at Biltmore"
        url={location.href}
      />
      <Hero {...heroProps} />
      <BookingBar hotelID={2654} />
      <Row {...rowProps}>
        <Col {...colProps}>
          <img
            alt={restaurant.logo.description}
            src={restaurant.logo.file.url}
            style={{ margin: `24px auto`, width: 175 }}
          />
          <SeoHeader
            seo={{ level: 1, content: `Azalea Bar & Kitchen` }}
            human={{
              level: 2,
              content: `The Taste of the New South.`,
            }}
          />
        </Col>
      </Row>
      <Row {...rowProps} align="middle">
        <Col {...colProps} style={{ textAlign: `left` }}>
          <div
            className={style.description}
            dangerouslySetInnerHTML={{
              __html: restaurant.description.childMarkdownRemark.html,
            }}
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <Row {...rowProps} gutter={[24, 24]}>
            <Col span={12}>
              <Img alt={cocktailImg.description} fluid={cocktailImg.fluid} />
            </Col>
            <Col span={12}>
              <Img alt={beerImg.description} fluid={beerImg.fluid} />
            </Col>
            {/* <Col span={24}>
              <Img {...diningRoomImgProps} />
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row
        {...rowProps}
        style={{
          background: `rgba(0,0,0,0.05)`,
          marginBottom: 32,
          marginTop: 32,
          paddingTop: 20,
          paddingBottom: 20,
          textAlign: `center`,
        }}
      >
        <Col {...menuHeaderColProps}>
          <Button
            size="large"
            type="primary"
            style={{ maxWidth: 325, width: `80%` }}
          >
            <a href="https://www.azaleakitchen.com/">
              Visit Restaurant Website
            </a>
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row {...rowProps}>
        <Col {...colProps}>
          <SeoHeader
            seo={{ level: 2, content: `Contact` }}
            human={{
              level: 4,
              content: `How Can We Serve You Today?`,
            }}
          />
        </Col>
      </Row>
      <Row {...contactRowProps}>
        <Col {...contactColProps}>
          <Typography.Title level={4}>{restaurant.name}</Typography.Title>
          <Typography.Paragraph style={{ margin: `24px auto` }}>
            Located in our lobby, Azalea Bar & Kitchen is open Tuesday through
            Saturday, 3 p.m. to 9 p.m.
          </Typography.Paragraph>
          <Typography.Paragraph strong>
            {restaurant.streetAddress}
            <br />
            {restaurant.cityStateZip}
          </Typography.Paragraph>
        </Col>
        <Col {...formColProps}>
          <RestaurantForm
            formName="Restaurant Contact"
            automationId={34}
            gtmClickId="contact-restaurant-form"
            syncDeal={false}
          />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "Azalea-Fried-Green-Tomatoes-med" }) {
      ...HeroFluid
    }
    beerImg: contentfulAsset(title: { eq: "Azalea-charcuterie-med" }) {
      description
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    cocktailImg: contentfulAsset(title: { eq: "Azalea - Cocktails 1" }) {
      description
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    # diningRoomImg: contentfulAsset(title: { eq: "rab-restaurant-bar-2" }) {
    #   description
    #   fluid(maxWidth: 500) {
    #     ...GatsbyContentfulFluid_withWebp
    #   }
    # }
    restaurant: contentfulRestaurant(name: { eq: "Azalea Bar & Kitchen" }) {
      name
      streetAddress
      cityStateZip
      phone
      logo {
        description
        file {
          url
        }
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
